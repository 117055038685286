exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-60-s-plus-club-js": () => import("./../../../src/pages/60s-plus-club.js" /* webpackChunkName: "component---src-pages-60-s-plus-club-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bootcamp-js": () => import("./../../../src/pages/bootcamp.js" /* webpackChunkName: "component---src-pages-bootcamp-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-group-training-js": () => import("./../../../src/pages/group-training.js" /* webpackChunkName: "component---src-pages-group-training-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-personal-training-js": () => import("./../../../src/pages/personal-training.js" /* webpackChunkName: "component---src-pages-personal-training-js" */),
  "component---src-pages-pilates-js": () => import("./../../../src/pages/pilates.js" /* webpackChunkName: "component---src-pages-pilates-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-rehabilitation-js": () => import("./../../../src/pages/rehabilitation.js" /* webpackChunkName: "component---src-pages-rehabilitation-js" */),
  "component---src-pages-sports-specific-training-js": () => import("./../../../src/pages/sports-specific-training.js" /* webpackChunkName: "component---src-pages-sports-specific-training-js" */),
  "component---src-pages-virtual-personal-training-js": () => import("./../../../src/pages/virtual-personal-training.js" /* webpackChunkName: "component---src-pages-virtual-personal-training-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-fitness-journal-js": () => import("./../../../src/templates/fitness-journal.js" /* webpackChunkName: "component---src-templates-fitness-journal-js" */),
  "component---src-templates-loc-js": () => import("./../../../src/templates/loc.js" /* webpackChunkName: "component---src-templates-loc-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

